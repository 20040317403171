import React, { useMemo } from 'react';
import type { GetStaticProps, NextPage } from 'next';

import { Home } from '@/components/pages';
import generateMetaTags from '@/utils/meta';
import { init, graphQLRequest, getHomeProps, HOME_QUERY } from '@/lib/graphql';
import type { HomeData } from '@/lib/graphql';

type Props = {
  data: HomeData;
};

const Index: NextPage<Props> = React.memo(function Index({ data }) {
  const homeProps = useMemo(() => getHomeProps(data), [data]);
  const metaTitle =
    'Precious Time - Find support and services for end of life care.';
  const metaDescription =
    'If you need end-of-life care, this website has been created to support you, your friends and family.';

  return (
    <>
      {generateMetaTags({
        title: homeProps.metaTitle ? homeProps.metaTitle : metaTitle,
        description: homeProps.metaDescription
          ? homeProps.metaDescription
          : metaDescription,
        skipTitleSuffix: true,
        image: homeProps.shareImage ? homeProps.shareImage : undefined,
      })}
      <Home {...homeProps} />
    </>
  );
});

export const getStaticProps: GetStaticProps<Props> = async () => {
  const response = await graphQLRequest(init(), HOME_QUERY);
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }

  return {
    props: { data: response.data as HomeData },
    revalidate: 1,
  };
};

export default Index;
